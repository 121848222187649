import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import {
  Section,
  Container,
  Row,
  Column,
  Image,
  Text,
  ScrollTo,
} from 'components/atoms';

import { useWindowSize } from 'utils/windowSizeHook';

const BannerFullScreen = ({ data, className }) => {
  const width = useWindowSize();
  const [isDesktop, setIsDesktop] = useState(width > 1024 ? true : false);
  const { caption, link, media } = data;
  const { title_html: title, subtitle_html: subtitle } = caption;
  const { path, text } = link;
  const { video, intro, image } = media || {};
  const { source_url: intro_url } = intro;

  const {
    alt_text: image_alt,
    source_url: image_link,
    title: image_title,
    media_details,
  } = image;
  const { source_url: video_link } = video;

  if (width) {
    let newWidth = width > 1024 ? true : false;
    if (isDesktop !== newWidth) {
      setIsDesktop(newWidth);
    }
  }

  const texts = [
    {
      type: 'text',
      tag: 'h1',
      title: title,
    },
    {
      type: 'text',
      tag: 'p',
      title: subtitle,
      className: 'body-lg',
    },
  ];

  const [playedGreetingsVideo, setPlayedGreetingsVideo] = useState(null);
  const [showDefaultVideo, setShowDefaultVideo] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const [greetingSrc, setGreetingSrc] = useState(null);
  const greetingRef = useRef(null);
  const videoRef = useRef(null);

  const hideGreetingVideo = () => {
    localStorage.setItem('videoSeen', true);
    setPlayedGreetingsVideo(true);
  };

  const changeDefaultVideoVisibility = () => {
    setDefaultVideo();
    greetingRef.current && greetingRef.current.play();
  };

  useEffect(() => {
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        setPlayedGreetingsVideo(!!localStorage.getItem('videoSeen'));
      }
    }, 0);
  }, []);

  const setDefaultVideo = () => {
    if (isDesktop && !videoSrc) {
      setVideoSrc(video_link);
    }
  };

  useEffect(() => {
    if (playedGreetingsVideo !== null) {
      if (!playedGreetingsVideo) {
        setGreetingSrc(intro_url);
      } else {
        setDefaultVideo();
      }
    }
  }, [playedGreetingsVideo]);

  useEffect(() => {
    if (playedGreetingsVideo) {
      setDefaultVideo();
    }
  }, [isDesktop]);

  useEffect(() => {
    if (videoSrc && videoRef.current) {
      videoRef.current.play();
      setShowDefaultVideo(true);
    }
  }, [videoSrc]);

  return (
    <Section className={cn('banner-video', [className])}>
      <div className="banner-video__video-bg">
        {playedGreetingsVideo !== null &&
          !playedGreetingsVideo &&
          isDesktop && (
            <video
              ref={greetingRef}
              key="greeting_video"
              controls={false}
              autoPlay={true}
              loop={false}
              muted={true}
              src={greetingSrc}
              className="greeting-video"
              onLoadedData={changeDefaultVideoVisibility}
              onEnded={hideGreetingVideo}
            />
          )}
        <video
          ref={videoRef}
          key="intro_video"
          autoPlay={true}
          controls={false}
          loop={true}
          muted={true}
          src={videoSrc}
          className={cn('default-video', {
            'default-video_active': showDefaultVideo,
          })}
        />
        <Image
          src={image_link}
          alt={image_alt}
          title={image_title}
          image={media_details}
        />
      </div>
      <Container>
        <Row>
          <Column lg="7" col="12">
            {texts.map(({ type, tag, href, title, className }, index) => {
              switch (type) {
                case 'text':
                  const isTitle = tag.match(/h\d/);

                  return (
                    <Text
                      tag={tag}
                      className={cn(
                        className,
                        `banner-video${isTitle ? '__title' : '__description'}`
                      )}
                      key={index}
                    >
                      {title}
                    </Text>
                  );
                default:
                  return null;
              }
            })}
          </Column>
        </Row>
        <ScrollTo className="" title={text} anchor={path} />
      </Container>
    </Section>
  );
};

BannerFullScreen.defaultProps = {
  className: '',
};

BannerFullScreen.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default BannerFullScreen;
